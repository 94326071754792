import React, { useEffect, useState } from "react";
import {getBiblioteca} from '../../lib/api/common'
import ProductImage from "../common/ProductImage";
import { Modal } from 'antd';

const UltimosAnadidosBiblioteca = () => {
    const [bibliotecaData, setBibliotecaData] = useState([]);
    const [isModalVisible, setisModalVisible] = useState(false);
    const [iframSrc, setIframeUrl] = useState('');

    const onProductSelected = (productId) =>{
        setIframeUrl('https://content.eccediciones.com/comicreader/comic/'+ productId);
        setisModalVisible(true);
    }

    useEffect(() => {        
        const fetchData = async () => {
            let request = 'ShopId=1&PageNo=1&PageSize=4';
            let response = await getBiblioteca(request);
            if(response.responseCode == '201') {
                setBibliotecaData(response?.data);                
            } else {
                setBibliotecaData([]);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            {bibliotecaData.length > 0 &&
            bibliotecaData.map((val, i) =>
                (<div key={`biblio${i}`} className="cubierta" onClick={() => onProductSelected(val.productId)}>
                    <ProductImage imageName={val.image} imgTag={false}  slug={'#'}/>                    
                </div>))
            } 
             <Modal visible={isModalVisible} className="viewerModal" onCancel={() => setisModalVisible(!isModalVisible)} keyboard={true}>
                <iframe src={iframSrc} width={'100%'} height={'100%'}></iframe>     
            </Modal>              
        </>
    )
}
export default UltimosAnadidosBiblioteca;