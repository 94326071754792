import Image from 'next/image';
import Link from 'next/link';
import React,{useState, useEffect} from 'react';
import MinisiteBannersImage from "./MinisiteBannersImage";


const MinisiteBanners = ({getdata, getImage}) => {
    
    const [widthHeight, setwdidthHeight] = useState([506, 198]);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        setWidth(window.innerWidth);

        return () => window.removeEventListener('resize', handleResize);

      }, []);

    useEffect(() => {
        if(width < 768) {
            setwdidthHeight([253, 198]);
        }else{
            setwdidthHeight([506, 198]);
        }
    }, [width]);

    return(
        <div className="container-fluid mb-50 banners">
            <div className="row">                                
                <div className="col-sm-12 col-md-8 tortues cover-h">                    
                    <a href={getdata(4)[0]?.link} rel="noreferrer" target={getdata(4)[0]?.newWindow == 1 ? '_blank' : '_self'}>
                        <MinisiteBannersImage zoom={getdata(4)[0]?.zoom} imagesrc={getImage(getdata(4)[0]) } width={widthHeight[0]} height={widthHeight[1]}/>                         
                        <div className="bannerText" style={{color:`${getdata(4)[0]?.textColor}`}} dangerouslySetInnerHTML={{__html: getdata(4)[0]?.text}} ></div>
                    </a>
                </div>                                                    
                <div className="col-sm-12 col-md-4 pooerosas cover-h" >   
                    <a href={getdata(5)[0]?.link} rel="noreferrer" target={getdata(5)[0]?.newWindow == 1 ? '_blank' : '_self'} >                     
                        <MinisiteBannersImage zoom={getdata(5)[0]?.zoom} imagesrc={getImage(getdata(5)[0]) } width={253} height={198}/>                        
                        <div className="bannerText" style={{color:`${getdata(5)[0]?.textColor}`}} dangerouslySetInnerHTML={{__html: getdata(5)[0]?.text}} ></div>
                    </a>                
                </div>                
                
                <div className="col-sm-12 col-md-4 justice cover-h">                        
                    <a  href={getdata(6)[0]?.link} rel="noreferrer"  target={getdata(6)[0]?.newWindow == 1 ? '_blank' : '_self'} >
                        <MinisiteBannersImage zoom={getdata(6)[0]?.zoom} imagesrc={getImage(getdata(6)[0]) } width={253} height={198}/>                        
                        <div className="bannerText" style={{color:`${getdata(6)[0]?.textColor}`}} dangerouslySetInnerHTML={{__html: getdata(6)[0]?.text}} ></div>
                    </a>        
                </div>                                                        
                <div className="col-sm-12 col-md-8 ricky cover-h" >                        
                    <a href={getdata(7)[0]?.link} rel="noreferrer"  target={getdata(7)[0]?.newWindow == 1 ? '_blank' : '_self'}>
                        <MinisiteBannersImage zoom={getdata(7)[0]?.zoom} imagesrc={getImage(getdata(7)[0]) } width={widthHeight[0]} height={widthHeight[1]}/>                            
                        <div className="bannerText" style={{color:`${getdata(7)[0]?.textColor}`}} dangerouslySetInnerHTML={{__html: getdata(7)[0]?.text}} ></div>
                    </a>
                </div>                                
            </div>
        </div>
    )
}

export default MinisiteBanners;
