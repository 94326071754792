import React,{useRef,useState} from 'react';
import useInViewport from '../../lib/hooks/useInViewport';
import Image from "next/image";
import dummy_image from  '../../public/assets/images/dummyimage.png';
import { useEffect } from 'react';

const MinisiteBannersImage = ({imagesrc, width, height, zoom}) => {

    const ref = useRef();
    const inViewport = useInViewport(ref, '0px');
    const [imageNameSRC, setImageNameSRC] =  useState(process.env.NEXT_PUBLIC_IMAGE_PATH + imagesrc)   

    useEffect(()=>{
        setImageNameSRC(process.env.NEXT_PUBLIC_IMAGE_PATH + imagesrc)
    },[imagesrc])

    return(

        <div className={`zoom-in ${inViewport && zoom? 'animation' : ''}`} ref={ref}>
            {imageNameSRC && <Image quality={100} src={imageNameSRC} alt="" layout="responsive" width={width} height={height} onError={() => {
                    setImageNameSRC(dummy_image);}}></Image>}
        </div>

    )
}

export default MinisiteBannersImage;
