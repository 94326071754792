import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import GuiasLecturaSlider from "./GuiasLecturaSlider";
import MinisiteBanners from "./MinisiteBanners";
import UltimosAnadidosBiblioteca from "./UltimosAnadidosBiblioteca";
import BannerImageComponent from "./BannerImageComponent";
import ContentImage from '../common/ContentImage.jsx';
import useInViewport from '../../lib/hooks/useInViewport';

const BannerComponent = ({getdata, type, homebanners, resolution}) => {        

    const getImage =(data)=>{
        return resolution > 767 ? data?.image :  data?.imageMobile ?  data?.imageMobile :  data?.image
    }
    return (
        <>
        {type == '1' ?            
            <div className="container-fluid homepagecontestsec">
                <div className="row">                    
                    <div className="col-6 dark-night">                        
                            <a href={getdata(2)[0]?.link} rel="noreferrer" target={getdata(2)[0]?.newWindow == 1 ? '_blank' : '_self'}> 
                                <BannerImageComponent zoom ={getdata(2)[0]?.zoom}  imagesrc={getImage(getdata(2)[0])} isImageComponent={true}/>
                                {/*<img src={process.env.NEXT_PUBLIC_IMAGE_PATH + getdata(2)[0]?.image} alt=""></img>*/}
                                <div className="banner_text_wrapper">
                                    <div className="homepage_contest_content" style={{color:`${getdata(2)[0]?.textColor}`}} dangerouslySetInnerHTML={{__html: getdata(2)[0]?.text}} ></div>
                                </div>                                                        
                            </a>
                    </div>                                        
                    <div className="col-6 tshirtdiv">                        
                        <div className="row">                            
                            <div className="col-12 homepage_contest">
                                <a href={getdata(3)[0]?.link} rel="noreferrer" target={getdata(3)[0]?.newWindow == 1 ? '_blank' : '_self'}>
                                    <BannerImageComponent zoom ={getdata(3)[0]?.zoom}  imagesrc={getImage(getdata(3)[0])} isImageComponent={true}/>
                                    {/*<img src={process.env.NEXT_PUBLIC_IMAGE_PATH + getdata(3)[0]?.image} alt=""></img>*/}
                                    <div className="banner_text_wrapper">
                                        <div className="homepage_contest_content" style={{color:`${getdata(3)[0]?.textColor}`}} dangerouslySetInnerHTML={{__html: getdata(3)[0]?.text}} ></div>
                                    </div>                                    
                                </a>
                            </div>                            
                        </div>                        
                    </div>                    
                </div>
            </div>            
        : type == '2' ?
            <MinisiteBanners getdata={getdata} getImage={getImage}></MinisiteBanners>
        : type == '3' ?
            <GuiasLecturaSlider bannersGuias={getdata(8)} resolution={resolution}/>
        : type == '4' ?
            <>
                <Link href="https://www.eccediciones.com/biblioteca" passHref={true} >
                    <div className="container-fluid homepagelastsec" >                    
                        {resolution && <ContentImage imageName={getImage(getdata(9)[0])} />}
                        <div className="container main-container biblioteca-text">
                            <h2>{getdata(9)[0]?.alternateText}</h2>
                            <p>{getdata(9)[0]?.text}</p>
                            <a href="https://www.eccediciones.com/biblioteca" className="btn-white">Ver bliblioteca</a>
                        </div>
                    </div>
                </Link>            
                <div className="container main-container biblioteca-text">
                    <div className="respos">
                        <h5>ÚLTIMOS CÓMICS AÑADIDOS</h5>
                        <div className="row ultimos">
                            <UltimosAnadidosBiblioteca></UltimosAnadidosBiblioteca>
                        </div>
                    </div>
                </div>
                <hr className="hidden-sm hr"></hr>
            </>
        : ''
        }
        </>
    )
}

export default BannerComponent;
